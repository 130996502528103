'use strict';

import { webUtils } from './web-utils.js';
import { pricingUtils } from './pricing-utils.js';

var pricingObj = pricingUtils.pricingObj;

export var bannerSetup = (function () {

    function toggleBanner() {
        if (pricingObj.isValid) {
            if (($('#nav-logged-in').hasClass('hidden') == true || $('.navbar-minimal').length > 0) && pricingObj != null) {
                var activated = webUtils.getCookie('banner-activated');
                if (activated) {
                    $('.banner').css({
                        opacity: 1,
                        top: '0px',
                        display: 'block',
                        'transition': 'none'
                    }).addClass('active').find('.box-inner').css('visibility', 'visible');
                } else {
                    $('.banner').css({
                        opacity: 1,
                        top: '0px',
                        display: 'block',
                    }).addClass('active').find('.box-inner').css('visibility', 'visible');
                    webUtils.setCookie('banner-activated', 'true');
                }
                $('body').addClass('active-banner');
                if (activated) {
                    $('body.active-banner header').css({ 'transition': 'none' });
                }
            }

        } else {
            // Hide banner if no offer in URL
            $('body').removeClass('active-banner');
            $('.banner').hide();
        }
    }

    function updateBannerHTML(new_html) {
        $('.banner .box').html(new_html);
    }

    function toggleSecondaryBanner(new_html) {
        if ($('#nav-logged-in').hasClass('hidden') == true || ($('.navbar-minimal').length > 0 && !$('body').hasClass('sunbasket-logged-in'))) {
            updateBannerHTML(new_html);
            $('.banner').css({
                opacity: 1,
                top: '0px',
                display: 'block',
            }).addClass('active').find('.box-inner').css('visibility', 'visible');
            $('body').addClass('active-banner');
            $('body').toggleClass('active-gift-banner');
        }
    }

    return {
        toggleBanner: toggleBanner,
        toggleSecondaryBanner: toggleSecondaryBanner,
    };
})();
