$(function () {
    if ($('#wellness-form').length) {

        $('.number-of-weeks').on('click', '.btn', function () {
            let date = new Date();
            if ($(this).data('weeks') == 0) {
                $('.program-expiration').text('you cancel');
            } else {
                date.setDate(date.getDate() + ($(this).data('weeks') * 7));
                $('.program-expiration').text(date.toLocaleDateString("en-US"));
            }
        });

        $('.segmented-control').on('click', '.btn', function () {
            var btnGroup = $(this).parent();
            btnGroup.find('.btn').removeClass('active');
            $(this).addClass('active');
        });

        if (window.stripe) {
            var stripe = window.stripe;

            var elements = stripe.elements();
            var cardElement = elements.create('card', {
                style: {
                    base: {
                        '::placeholder': {
                            color: '#ccc',
                        },
                    },
                },
            });

            if ($('#card-element').length) {
                cardElement.mount('#card-element');
            }

            cardElement.on('change', function (event) {
                $('#card-errors').text(event.error ? event.error.message : '');

                if (event.complete) {
                    $('button[type=submit]').enableButton();
                }
            });

            function sanitizeForm($input) {
                var $output = $input.
                    replace(/<script[^>]*?>.*?<\/script>/gi, '').
                    replace(/<[\/\!]*?[^<>]*?>/gi, '').
                    replace(/<style[^>]*?>.*?<\/style>/gi, '').
                    replace(/<![\s\S]*?--[ \t\n\r]*>/gi, '').
                    replace(/&nbsp;/g, '').
                    replace(/([,.€&><"*#()^%?!{}\/])+/g, '');
                return $output;
            };

            $('#stripe-form').on('submit', function (e) {
                var form = $(this)[0];
                e.preventDefault();
                $('button[type=submit]', this).disableButton();

                $('#card-errors').text('');
                var $nameOnCard = sanitizeForm($('#nameOnCard').val());

                stripe.createToken(cardElement, {
                    name: $nameOnCard,
                    address_country: 'US'
                }).then(function (result) {
                    if (result.error) {
                        if (result.error.type === 'card_error') {
                            $('#card-errors').text(result.error.message);
                        } else {
                            $('#card-errors').text('Something went wrong with creating credit card token. Please try again or contact our Customer Service.');
                        }
                        $('button[type=submit]').enableButton();

                        return;
                    }

                    $('#cardToken').val(result.token.id);

                    form.submit();
                }).catch(function () {
                    $('#card-errors').text('Something went wrong with creating credit card token. Please try again or contact our Customer Service.');
                    $('button[type=submit]').enableButton();
                });

                return false;
            });
        }
    }
});
