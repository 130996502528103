import { webUtils } from './web-utils.js';

// img tags
$(document).ready(function () {
    $('.srcset').each(function () {
        var $this = $(this);
        $this.attr('data-sizes', $this.data('fixed-sizes') ? $this.data('fixed-sizes') : $this.width() + 'px');
        $this.removeAttr('data-fixed-sizes');
    });

    /*
    * backgrounds,
    * requires adding "responsive-bg lazy" or "responsive-bg-simple lazy" class to the element 
    */
    var hpr = webUtils.highPixelRatio();

    $('.responsive-bg').each(function () {
        var $this = $(this);
        var el_width = $this.innerWidth();
        var bg_attr = hpr ? '768-bg' : '380-bg';
        
        if (el_width > 1536) {
            bg_attr = hpr ? 'full-bg' : '2048-bg';
        } else if (el_width > 1024) {
            bg_attr = hpr ? '2048-bg' : '1536-bg';
        } else if (el_width > 768) {
            bg_attr = hpr ? '1536-bg' : '1024-bg';
        } else if (el_width > 380) {
            bg_attr = hpr ? '1024-bg' : '768-bg';
        }

        if ($this.hasClass('no-lazy')) {
            $this.attr('style', 'background-image: url("' + $this.data(bg_attr) + '")');
        } else
        $this.attr('data-src', $this.data(bg_attr));
    });

    $('.responsive-bg-simple').each(function () {
        var $this = $(this);
        $this.attr('data-src', $this.data($this.innerWidth() > 600 ? '2048-bg' : '1024-bg'));
    });
});
