'use strict';

import { webUtils } from './web-utils.js';
import { getQueryString } from './sunbasket.js';

$(document).ready(function() {
    function triggerDonationValueClickedEvent(amount) {
        amount && window.analytics && window.analytics.track('Donation Value Clicked', {
            donation_amount: amount,
            third_party_order: 'PRG',
            via: 'donation_flow'
        });
    }

    var $customAmountInput = $('#customAmount');

    $('.amount-btn').on('click', function() {
        var amount = $(this).data('amount');
        $('.amount-input').val(amount);
        $customAmountInput.val('').removeClass('selected');
        $('.dollar-sign').hide();

        triggerDonationValueClickedEvent(amount);
    });

    $customAmountInput.click(function() {
        $('.amount-btn').removeClass('active');
        $(this).addClass('selected');
        $('.dollar-sign').show();

        $customAmountInput.keyup(function() {
            $('.amount-input').val($(this).val());
        }).keyup();
    });

    $customAmountInput.on('change', function () {
        triggerDonationValueClickedEvent($(this).val());
    });


    var $donationForm = $('#donationForm');

    $donationForm.submit(function(event) {
        event.preventDefault();
        var donationValue = parseFloat($('.amount-input').val());

        if (donationValue && donationValue >= 10 && donationValue <= 2000) {
            webUtils.setCookie('donationAmount', donationValue, 1);

            var queryString = getQueryString(), qs = [];
            var preservedProperties = ['token', 'iOSNative', 'noHeader'];

            for (var i = 0; i < preservedProperties.length; i++) {
                if (queryString.hasOwnProperty(preservedProperties[i])) {
                    qs.push(preservedProperties[i] + "=" + queryString[preservedProperties[i]]);
                }
            }

            var redirectParams = qs.length ? '?' + qs.join('&') : '';

            window.location.href = '/donate/prg' + redirectParams;
        }
    });

    $('.donation-submit').on('click', function (e) {
        e.preventDefault();
        $donationForm.submit();
    })
});
