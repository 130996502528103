'use strict';

import { webUtils } from './web-utils.js';
import { pricingUtils } from './pricing-utils.js';
import { bannerSetup } from './banner-setup.js';

let pricingConfig = pricingUtils.pricingObj;

function run() {
    $(document).ready(function () {
        pricingUtils.retrieveOffer().then(function () {
            pricingUtils.getServerRules().then(function () {
                main();
            }).catch(function (error) {
                console.error('Pricing offer functionality failed to initialize');
                console.error(error);
            });
        }).catch(function (error) {
            console.error('Pricing offer functionality failed to initialize');
            console.error(error);
        });
    });
}

run();

// this block setups up everthing after rules and document is loaded
function main() {
    if ($('#wellness-form').length) {
        let mealPrice = pricingUtils.getCheapest('MEAL_KIT').pricePerServing;

        $('.gift-card-price').text('$' + $('.number-of-meals .btn.active').data('meals') * mealPrice);
        $('.meal-price').text('$' + mealPrice);
        $('.meal-qty').text($('.number-of-meals .btn.active').data('meals'));
        $('input#pricePerServing').val(mealPrice);

        $('.number-of-meals').on('click', '.btn', function () {
            $('.gift-card-price').text('$' + parseInt($(this).data('meals')) * mealPrice);
            $('.meal-qty').text($(this).data('meals'));
        });
    }

    var selector = '.btn-primary, .btn-white, .cta-link, a[href="/join"], a[href="/join/"], .join, a.link, .append-me, .home-page .join';
    var jfSelector = 'a[href*="/join"]';
    webUtils.appendJoinFlowVariation(jfSelector);

    updateServingPrice();

    checkBodyReady('body.ready').then(function () {
        setupDiscounts();
    });

    webUtils.setCookie('landing-page-pathname', window.location.pathname);

    ctaClickHandler();
    cookieToPopulateJoinFlow();

    if ($('.pricing-meal-kits-only').length > 0) {
        let chooseButtons = $('.meal-kit-pricing-item .btn.btn-choose');
        let signupForm = $('#pricing-meal-kits-only-form');
        
        setupMealKitOnlyCards();
        updateMealKitOnlyPlans()

        chooseButtons.click(function() {
            $('#numberOfMealsMk', signupForm).val($(this).closest('.meal-kit-pricing-item').data('meals'));
            $('#numberOfPeopleMk', signupForm).val($(this).closest('.meal-kit-pricing-item').data('people'));

            if (signupForm.length) {
                sendJoinMealKitPricingPlanForm(signupForm);
            }
        });
    }

    if ($('.pricing-frss-only').length > 0) {
        let chooseButtons = $('.frss-pricing-item .btn.btn-choose');
        let signupForm = $('#pricing-frss-only-form');

        chooseButtons.click(function() {
            $('#numberOfMealsFrss', signupForm).val($(this).closest('.frss-pricing-item').data('meals'));
            
            if (signupForm.length) {
                sendJoinFrssPricingPlanForm(signupForm);
            }
        });
    }
}

function updateServingPrice() {
    $('#servingPrice')
        .text("$" + pricingUtils.getCheapest().pricePerServing)
        .parents('#dinnerSentence')
        .removeClass('hidden');

    tryPageServingPrice();
}

function setupDiscounts() {
    if (pricingConfig.isValid && ((!$('body').hasClass('sunbasket-logged-in') && !webUtils.hasCookie('is_authenticated')) || $('body').hasClass('sign-up-incomplete'))) {
        let cheapest = pricingUtils.getCheapest();
        if (cheapest.pricePerServing !== cheapest.discountedPricePerServing) {
            $('#servingPrice').addClass('strikeout');

            $('#slashOutServingPrice').text('$' + cheapest.discountedPricePerServing);

            tryPageDiscounts();
        }

        globallyPopulateOfferMessage();
        bannerSetup.toggleBanner();
    } else {
        $('body').removeClass('active-banner');
        $('banner').removeClass('active');

        var jfVariation = webUtils.getCookie('join-flow-variation');
        var joinPath = (jfVariation && jfVariation.length > 0) ? '/join/' + jfVariation : '/join';

        var new_html = '<div class="box-inner"><a class="cta-link" href="' + joinPath + '"><span class="offer-message">Order today and get free shipping on your first delivery</span></a><a class="btn btn-white btn-sm cta-link" href="' + joinPath + '">Order Now</a></div>';
        bannerSetup.toggleSecondaryBanner(new_html);
    }

    $(document).trigger('sunbasket-discounts-setup');
}

function tryPageServingPrice() {
    const $el = $('.try-page-discounts');

    if ($el.length === 0) {
        return;
    }

    $el.find('.discount-single-serving')
        .removeClass('active');

    $el.find('.price-per-serving')
        .removeClass('original-price')
        .text('$' + pricingUtils.getCheapest().pricePerServing);

    $el.css('opacity', '1').removeClass('hide').show();
}

function tryPageDiscounts() {
    let cheapest = pricingUtils.getCheapest();

    const $el = $('.try-page-discounts');

    if ($el.length === 0 || cheapest.pricePerServing === cheapest.discountedPricePerServing) {
        return;
    }

    $el.find('.price-per-serving')
        .addClass('original-price');

    $el.find('.discount-single-serving')
        .text('$' + cheapest.discountedPricePerServing)
        .addClass('active');
}

function globallyPopulateOfferMessage(path) {
    if (pricingConfig.isValid) {
        var selector = '.try .navbar a[href*="/menu"], .btn-primary, .btn-white, .cta-link, .join, a.link, .append-me, .home-page .join';
        var jfSelector = 'a[href*="/join"]';

        // Dynamically populate offer message anywhere it exists
        $('.offer-message').text(pricingConfig.message);
        if (pricingConfig.terms && pricingConfig.terms.length > 0) {
            $('.offer-terms-asterisk').removeClass('hide');
            $('.legal-text.offer-terms-footer').removeClass('hide');
            $('.offer-tooltip-terms-footer').attr('data-original-title', pricingConfig.terms);
        }

        // Append CTA urls so that roffer passes through to join flow
        webUtils.appendJoinFlowVariation(jfSelector);
        webUtils.appendParams(selector);
    }
}

//set cookies based on meal plan button click for Explore section

function cookieToPopulateJoinFlow() {
    $('.meal-plans-tile-btn, .mobile-meal-plans-tile-btn').on('click', function (e) {
        window.sunbasket.joinPreferences.save({ mealPlan: this.id });
        webUtils.deleteCookie('noPrepPreferred');
    });

    if ($('body.single-meal_plans').length > 0) {
        var data = $('.hero-landing.data-store').attr('data-id');

        $('.btn-primary').on('click', function () {
            window.sunbasket.joinPreferences.save({ mealPlan: data });
            webUtils.deleteCookie('noPrepPreferred');
        });
    }

    if ($('body.single-try').length > 0) {
        $('.offer-btn').on('click', function () {
            var data = $('.hero.data-store').attr('data-id');
            window.sunbasket.joinPreferences.save({ mealPlan: data });
            webUtils.deleteCookie('noPrepPreferred');
        });
    }
}

function checkBodyReady(element) {
    return new Promise(function (resolve, reject) {
        waitForContainerElement(resolve, element);
    });
}

function waitForContainerElement(resolve, element) {
    var $configElement = $('body.ready');
    if ($configElement.length === 0) {
        setTimeout(waitForContainerElement.bind(this, resolve), 300);
    } else {
        resolve($configElement);
    }
}

function ctaClickHandler() {
    $('.pricing-table.offer-btn').on('click', function (e) {
        var values = retrieveValues($(e.target).closest('.plan-wrapper'));
        webUtils.setCookie('pricingTableMenuValues', JSON.stringify(values), false);
    });
}

function retrieveValues($pricingTable) {
    var $value_wrappers = $pricingTable.find('.value-wrapper'),
        values = {};

    values.menu = $pricingTable.attr('id');

    $value_wrappers.each(function () {
        var name = $(this).attr('data-name'),
            value,
            $el;

        if ($(this).find('.value') && $(this).find('.value').length > 0) {
            $el = $(this).find('.value');
        } else {
            $el = $(this);
        }

        value = ($el.val() && $el.val().length > 0) ? $el.val() : $el.text();
        values[name] = value;
    });
    return values;
}

function updateMealKitOnlyPlans() {
    $('.pricing-meal-kits-only').on('click', '.meal-kit-pricing-item .btn-group .btn', function () {
        let btnGroup = $(this).parent();
        var parentCard = $(this).closest('.meal-kit-pricing-item');

        btnGroup.find('.btn').removeClass('active value');
        $(this).addClass('active').addClass('value');
        parentCard.data('people', $(this).val());

        populateMealKitOnlyPlans(parentCard);
    });
}

function populateMealKitOnlyPlans(parentCard) {
    var numberOfMeals = parseInt(parentCard.data('meals'));
    var numberOfPeople = parseInt(parentCard.data('people'));
    var totalServings = numberOfMeals * numberOfPeople;
    parentCard.find('.total-servings').text(totalServings);
}

function setupMealKitOnlyCards() {
    $('.pricing-meal-kits-only .meal-kit-pricing-item').each(function () {
        populateMealKitOnlyPlans($(this));
    });
}

function sendJoinMealKitPricingPlanForm(signupForm) {
    var zionSingleActionUrl = webUtils.getAjaxUrl('/join/mealkits/plan');
    signupForm.attr('action', zionSingleActionUrl);

    var form = signupForm;

    webUtils.getCsrfToken(function (csrfResponse) {
        $('#csrfTokenPricingMk', signupForm).val(csrfResponse.token);
        form.submit();
    });
}

function sendJoinFrssPricingPlanForm(signupForm) {
    var frssOnlyActionUrl = webUtils.getAjaxUrl('/join/fresh-ready/plan');
    var easySetupActionUrl = webUtils.getAjaxUrl('/cms/api/join');
    
    if (signupForm.hasClass('frss-only')) {
        signupForm.attr('action', frssOnlyActionUrl);
    } else if (signupForm.hasClass('easy-setup')) {
        signupForm.attr('action', easySetupActionUrl);
    }
    
    var form = signupForm;

    webUtils.getCsrfToken(function (csrfResponse) {
        $('#csrfTokenPricingFrss', signupForm).val(csrfResponse.token);

        form.submit();
    });
}
