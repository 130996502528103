"use strict";

//sunbasket things
import "babel-polyfill";

import $ from "jquery";
window.jQuery = $;
window.$ = $; // lazy fix if you want to use jQuery in your inline scripts.

require("./theme/web-utils.js");
require("./theme/responsive-images.js");
require("./theme/lazy-loading.js");
require("./theme/recipes-api.js");
require("./theme/pricing-utils.js");
require("./theme/banner-setup.js");
require("./theme/navbar-setup.js");
require("./theme/sunbasket.js");
require("./theme/offer-functionality.js");
require("./theme/direct-join.js");
require("./theme/join-waitlist.js");
require("./theme/hero-join-form.js");
require("./theme/try-recipes-carousel.js");
require("./theme/try-explore.js");
require("./theme/try-download-app.js");
require("./theme/sticky-bottom.js");
require("./theme/meal-plans-nav.js");
require("./theme/back-to-top.js");
require("./theme/url-parameters.js");
require("./theme/recipe-roundup-page.js");
require("./theme/donation-form.js");
require("./theme/zion-menu.js");
require("./theme/join-analytics.js");
require("./theme/covid-banner.js");
require("./theme/competitive-matrix.js");
require("./theme/organic-page.js");
require("./theme/blog.js");
require("./theme/chatbot.js");
require("./theme/wellness-stripe-form.js");
require("./theme/young-city-solo.js");
require("./theme/dietid-form.js");
