'use strict';
import { webUtils } from './web-utils.js';

export let pricingUtils = (function() {
    let pricingObj = {};

    webUtils.getCookiesfromMarkup();

    if (webUtils.getParameterByName('offer')) {
        pricingObj.params = parseOfferParams();
        if (!!pricingObj.params.offer) {
            pricingObj.offer = pricingObj.params.offer;
        }
    } else if (webUtils.getCookie('offer')) {
        pricingObj.offer = webUtils.getCookie('offer');
    }

    function parseOfferParams() {
        let parsedParams = { 
            'offer': webUtils.getParameterByName('offer'),
            'utm_source': webUtils.getParameterByName('utm_source'),
            'utm_headline': webUtils.getParameterByName('utm_headline'), 
            'utm_campaign': webUtils.getParameterByName('utm_campaign'),
            'utm_content': webUtils.getParameterByName('utm_content'),
            'source': webUtils.getParameterByName('source'),
            'debug': webUtils.getParameterByName('debug'),
        }; 
        return parsedParams;
    }

    const getServerRules = async () => {
        try {
            processPricing(await $.ajax({
                cache: false,
                url: webUtils.getAjaxUrl('/cms/api/subscription-settings'),
                data: {
                    offer: pricingObj.offer
                },
            }));
        } catch (error) {
            console.error('server pricing rules failed to load');
            console.error(error);
        }
    }

    function processPricing(subscriptionSettings) {
        pricingObj.lowestStartAtPrice = subscriptionSettings.lowestStartAtPrice;
        pricingObj.startAtPrices = subscriptionSettings.startAtPrices;
    }

    const retrieveOffer = async () => {
        if (pricingObj.offer) {
            try {
                let offer = await $.ajax({ cache: false, url: webUtils.getAjaxUrl('/cms/api/promos/' + pricingObj.offer), data: 'json' });
                Object.assign(pricingObj, offer);
                pricingObj.isValid = validateOffer();
            } catch (error) {
                webUtils.deleteCookie('offer');
                console.error(error);
            }
        } else {
            console.log('No offer');
        }
    }

    function validateOffer() {
        if (pricingObj.offer != null && pricingObj.message != null) {
            webUtils.setCookie('offer', pricingObj.offer, false);
            return true; 
        } else {
            webUtils.deleteCookie('offer');
            console.error('invalid offer');
            return false;
        }
    }

    return {
        retrieveOffer: retrieveOffer,
        getServerRules: getServerRules,
        pricingObj: pricingObj,
        getCheapest: function(planType) {
            if (!planType) {
                return pricingObj.lowestStartAtPrice;
            }

            return pricingObj.startAtPrices[planType.toUpperCase()];
        }
    };
})();