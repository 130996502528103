$(function () {
    $('.lazy').lazy({
        threshold: 0,
        enableThrottle: true,
        throttle: 250,
        threshold: 200
    });

    // Load images only for active slides
    $('[class*="slick-slider"]').each(function () {
        var $this = $(this);

        $($this).on('afterChange', function (event, slick, currentSlide, nextSlide) {
            $('.slick-active .lazy[data-src]', $this).lazy({
                bind: "event",
                delay: 0,
            });
        });
    });

    //Load images in modals
    $('.open-modal').click(function() {
        var $modal_id = $(this).data('target');
        $('.modals-collector ' + $modal_id + ' .lazy').lazy({
            bind: "event",
            delay: 0,
            threshold: 0
        });
    });
});