'use strict';
import { webUtils } from "./web-utils";

$(document).ready(function () {
    var mealKitsOnlyActionUrl = webUtils.getAjaxUrl('/join/mealkits');
    var zionFreshReadyActionUrl = webUtils.getAjaxUrl('/join/fresh-ready');

    var $signupForm = $('#mealKitsOnlyForm');
    var $zionFreshReadyForm = $('#zionFreshReadyForm');

    if ($signupForm.length) {
        $signupForm.attr('action', mealKitsOnlyActionUrl);
    } else if ($zionFreshReadyForm.length) {
        $signupForm = $zionFreshReadyForm;
        $signupForm.attr('action', zionFreshReadyActionUrl);
    }

    $signupForm.on('submit', function(e) {
        e.preventDefault();
        $('button[type=submit]', this).disableButton();

        var form = this;

        webUtils.getCsrfToken(function (csrfResponse) {
            $('#csrfToken', $signupForm).val(csrfResponse.token);

            form.submit();
        });

        return false;
    });

});
