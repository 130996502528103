import { webUtils } from "./web-utils.js";

function postToChatbotWebhook(userAnalyticsId, useAnalyticsId) {
  return webUtils.getCsrfToken(function (csrfResponse) {
    var chatbotEndpoint =
      "/chatbot/" + userAnalyticsId + "?useAnalyticsId=" + useAnalyticsId;

    $.post(webUtils.getAjaxUrl(chatbotEndpoint), {
      _csrf: csrfResponse.token,
    });
  });
}

$(window).on("load", function () {
  var isCustomerLoggedIn = webUtils.checkIsAuthCookie();
  var analyticsIdFromQueryParam = webUtils.getParameterByName("analyticsId");
  var analyticsId =
    analyticsIdFromQueryParam ||
    webUtils.getCookie("ajs_user_id") ||
    webUtils.getCookie("analyticsAnonymousId") ||
    webUtils.getCookie("ajs_anonymous_id");

  if (window && window.chat) {
    window.chat.setChatRegistrationField(
      "schema.conversation.customer.custom.isLoggedIn",
      isCustomerLoggedIn || analyticsIdFromQueryParam ? "yes" : "no"
    );

    var userApiEndpoint = "/cms/api/user";
    if (isCustomerLoggedIn) {
      $.ajax({
        url: webUtils.getAjaxUrl(userApiEndpoint),
        type: "GET",
        cache: true,
        xhrFields: { withCredentials: true },
        success: function (result) {
          const firstName = result.firstName;
          if (firstName) {
            window.chat.setChatRegistrationField(
              "schema.conversation.customer.firstName",
              firstName
            );
          }

          const serverSideAnalyicsId = result.analyticsId || result.id;
          if (serverSideAnalyicsId && serverSideAnalyicsId !== analyticsId) {
            analyticsId = serverSideAnalyicsId;
          }

          if (analyticsId) {
            window.chat.setChatRegistrationField(
              "schema.conversation.customer.custom.userAnalyticsId",
              analyticsId
            );
          }
        },
        error: function (e) {
          window.chat.setChatRegistrationField(
            "schema.conversation.customer.custom.userAnalyticsId",
            analyticsId
          );
          console.error("Error happend while getting the user data", e);
        },
      });
    } else {
      window.chat.setChatRegistrationField(
        "schema.conversation.customer.custom.userAnalyticsId",
        analyticsId
      );
    }
    if (window.location.pathname.includes("help")) {
      postToChatbotWebhook(analyticsId, !!analyticsIdFromQueryParam);
    }
  }
});
