$(function() {

    //Animate button when visible in viewport
    var $slider = $('.competitive-matrix .cm-table');
    var $gridItems = $slider.data('grid-items');
    var mobileBlink = 600;

    if ($gridItems == 2) {
        mobileBlink = 375;
    } else if ($gridItems == 3) {
        mobileBlink = 420;
    } else if ($gridItems == 4) {
        mobileBlink = 515;
    }

    if ($slider.length) {

        $.fn.visible = function(partial) {
            var $t = $(this),
                $w = $(window),
                viewTop = $w.scrollTop(),
                viewBottom = viewTop + $w.height(),
                _top = $t.offset().top,
                _bottom = _top + $t.height(),
                compareTop = partial === true ? _bottom : _top,
                compareBottom = partial === true ? _top : _bottom;

            return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
        };

        $(window).scroll(function(event) {
            if ($slider.visible(true) && !$slider.hasClass('blink') && $(window).width() <= mobileBlink) {
                setTimeout(function() {
                    $slider.addClass('blink');
                }, 500);
            }
        });
    }
});
