'use strict';

import {webUtils} from "./web-utils";

$(document).ready(function() {
    let $waitlistForm = $('.sb-waitlist-form');
    let waitlistDataCookieName = 'waitlistData';

    if ($waitlistForm.length && window.sunbasket.cookies.exists(waitlistDataCookieName)) {
        try {
            let waitlistData = window.sunbasket.cookies.getJson(waitlistDataCookieName);

            $waitlistForm.find('input[name="path"]').val(waitlistData.path);
            $waitlistForm.find('input[name="analytics_id"]').val(waitlistData.analytics_id);
            $waitlistForm.find('input[name="pricing_scheme"]').val(waitlistData.pricing_scheme);
            $waitlistForm.find('input[name="pricing_tier"]').val(waitlistData.pricing_tier);

            if (waitlistData.email && waitlistData.zip) {
                window.analytics.track('Registration Completed', {
                    value: 0.01,
                    currency: "USD",
                    via: "landing_page",
                    join_flow: "WAITLIST",
                    promo_code: waitlistData.promo_code,
                    email: waitlistData.email,
                    zip: waitlistData.zip
                });

                delete waitlistData.promo_code;
                delete waitlistData.email;
                delete waitlistData.zip;

                window.sunbasket.cookies.setJson(waitlistDataCookieName, waitlistData);
            }
        } catch (ignored) {
        }
    }
});

function handleErrorDuringWaitlistJoin($formContainer, internalErrorMsg) {
    console.error(internalErrorMsg);

    $formContainer.hide();

    $('.message-page .message-page-content').hide();
    $('.error-copy-container').show();

}

$('.sb-waitlist-form.join').on('submit', function(e) {
    e.preventDefault();

    let $form = $(this);
    let $formContainer = $form.parents(".form-container");

    if (!$form.parsley().isValid()) {
        return false;
    }

    let $submitButton = $('[type=submit]', $form);
    $submitButton.disableButton();

    let offer = webUtils.getParameterByName('offer');
    let targetUrl = webUtils.getAjaxUrl('/cms/api/join-waitlist');

    webUtils.getCsrfToken(function (csrfResponse) {
        let isUrgent = $form.find('input[name="urgent"]').is(':checked');

        if (offer && offer.length) {
            targetUrl += "?offer=" + offer;
        }

        let data = {
            email: $form.find('input[name="email"]').val(),
            zip: $form.find('input[name="zip"]').val(),
            urgent: isUrgent
        };

        $.ajax({
            url: targetUrl,
            data: JSON.stringify(data),
            contentType: 'application/json',
            type: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                [csrfResponse.headerName]: csrfResponse.token,
            },
        }).done(function(response, status, jqXHR) {
            let targetPath = jqXHR.getResponseHeader('Location');

            if (jqXHR.status !== 200 && (jqXHR.status !== 204 || !targetPath)) {
                handleErrorDuringWaitlistJoin($formContainer, "Something went wrong while posting waitlist form data to the CMS endpoint");
                return;
            }

            if (jqXHR.status === 204) {
                window.location.href = webUtils.getAjaxUrl(targetPath);
                return;
            }

            $formContainer.hide();

            if (isUrgent) {
                $('.waitlist-form .success-urgent-copy-container').fadeIn(1000);
            } else {
                $('.waitlist-form .success-copy-container').fadeIn(1000);
            }

            if (window.analytics) {
                window.analytics.identify(response.uuid, {
                    prospect_proxy: response.uuid,
                    email: data.email
                });

                webUtils.cookieProtectedOnce('sunbasket_analytics_join_waitlist_reg_completed', function () {
                    window.analytics.track('Registration Completed', {
                        value: 0.01,
                        currency: "USD",
                        via: "landing_page",
                        join_flow: "WAITLIST",
                        promo_code: response.promoCode,
                        email: data.email,
                        zip: data.zip
                    });
                });
            }

            webUtils.setCookie('analyticsAnonymousId', response.uuid);

        }).fail(function(jqXHR, textStatus, errorThrown) {
            if (jqXHR.status !== 400) {
                handleErrorDuringWaitlistJoin($formContainer, "Something went wrong while posting waitlist form data to the CMS endpoint");
            } else {
                jQuery.each(jqXHR.responseJSON, function (key, value) {
                    $('input[name="' + key + '"]', $form).parsley().addError('custom-error', {
                        message: value,
                        updateClass: true
                    });
                });
                $submitButton.enableButton();
            }
        });
    }, function (error) {
        handleErrorDuringWaitlistJoin($formContainer, "Unable to get CSRF token: " + error);
    });
});

$('.sb-waitlist-form.urgent').on('submit', function(e) {
    e.preventDefault();

    let $form = $(this);
    let $formContainer = $form.parents(".form-container");

    let $submitButton = $('[type=submit]', $form);
    $submitButton.disableButton();

    let isOver65 = $form.find('#over65').is(':checked');

    if (!isOver65) {
        $('#read-more').modal('hide');
        $submitButton.enableButton();

        return false;
    }

    webUtils.getCsrfToken(function (csrfResponse) {
        let data = {
            analyticsId: $form.find('input[name="analytics_id"]').val(),
        };

        $.ajax({
            url: webUtils.getAjaxUrl('/cms/api/join-waitlist-urgent'),
            contentType: 'application/json',
            data: JSON.stringify(data),
            type: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                [csrfResponse.headerName]: csrfResponse.token,
            },
        }).done(function(response, status, jqXHR) {
            let targetPath = jqXHR.getResponseHeader('Location');

            if (jqXHR.status !== 200) {
                handleErrorDuringWaitlistJoin($formContainer, "Something went wrong while posting waitlist form data to the CMS endpoint");
                return;
            }

            $('#read-more').modal('hide');

            $('.message-page .message-page-content').hide();
            $('.message-page .success-message-content').fadeIn(1000);

        }).fail(function(jqXHR, textStatus, errorThrown) {
            handleErrorDuringWaitlistJoin($formContainer, "Something went wrong while posting waitlist form data to the CMS endpoint");
        }).always(function() {
            $('#read-more').modal('hide');
            $submitButton.enableButton();
        });
    }, function (error) {
        $('#read-more').modal('hide');
        $submitButton.enableButton();

        handleErrorDuringWaitlistJoin($formContainer, "Unable to get CSRF token: " + error);
    });
});

$('#read-more', 'body.page-template-waitlist-success-page').on('shown.bs.modal', function () {
    let $waitlistForm = $('.sb-waitlist-form.urgent');

    window.analytics && window.analytics.track('Modal Displayed', {
        via: 'soldout_lp',
        path: $('input[name="path"]', $waitlistForm).val(),
        modal_name: 'covid_vulnerable',
        logged_in: false,
        CTA_destination: 'waitlist_success_vulnerable',
        pricing_scheme: $('input[name="pricing_scheme"]', $waitlistForm).val(),
        pricing_tier: $('input[name="pricing_tier"]', $waitlistForm).val(),
    });
});