$(function () {
    // Download Form Functionality
    $('#download_app_sms').submit(function (e) {
        e.preventDefault();
        $('button[type="submit"]').prop('disabled', true);
        var phoneNumber = $('#InputNumber').val().replace(/\D+/g, "");

        $.ajax({
            url: "/sms/download-mobile-app-link?phone=" + phoneNumber,
            method: "GET",
            complete: function () {
                $('.download-thank-you').fadeIn();
                $('button[type="submit"]').prop('disabled', false);
                $('button[type="submit"]').text('Text Me A Link');
            }
        });
    });
});
