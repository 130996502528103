import { webUtils } from './web-utils.js';

export var recipes = (function() {
    /**
     * This is our private request method, allows other methods to be more concise (easy to see how they interact with the API instead of implementation details)
     * @param {string} method
     * @param {string} path
     * @param {Object} data optional
     */
    function request(method, path, data) {
        data = data || {};
        path = path || '';
        return $.ajax({
            url: webUtils.getAjaxUrl(path),
            method: method,

            data: data,
        });
    }

    /**
     * Private shortcut to use `request` to make a GET request to the API
     * @param {string} path
     */
    function get(path, data) {
        return request('GET', path, data);
    }

    /**
     * Gets all the recipes as per the documentation for the /content/recipes route
     * @param {object} data optional query parameters
     * @param {function} callback
     */
    function recipes(data, callback) {
        return get('/cms/api/recipes-next-week/classic', data).done(callback);
    }

    // Expose our actual API methods (usually one-to-one with the route they would hit, relative to the base URL)
    return {
        get: recipes
    };
})();