//Recipes for Zion LP
'use strict';

// load webUtils lib
import { webUtils } from './web-utils.js';

function slugifyRecipeTag(str) {
    str = str.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
    str = str.replace(/^\s+|\s+$/gm, '');

    return str.replace(/\s+/g, '-');
}

function buildSlickRecipe(recipe, responsiveImageObj) {
    var diets = recipe.diets;
    var dietsAsString = diets.join(', ');
    var recipeLabelSlug = recipe.recipeLabel ? slugifyRecipeTag(recipe.recipeLabel) : '';
    var recipeLabel = '';

    if (recipe.recipeLabel) {
        recipeLabel = `<div class="tile-label-container tile-label-${recipeLabelSlug}">${recipe.recipeLabel}</div>`;
    }

    return `<div class="recipe-slide">
                    <div class="card" id="recipe-${recipe.id}">
                        ${recipeLabel}
                        <div class="recipe-img-container">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=" data-src="${responsiveImageObj.recipeImage400}" alt="name" class="lazy img-responsive recipe-card-img" data-srcset="${responsiveImageObj.recipeImage900} 900w, ${responsiveImageObj.recipeImage800} 800w, ${responsiveImageObj.recipeImage600} 600w, ${responsiveImageObj.recipeImage400} 400w, ${responsiveImageObj.recipeImage300} 300w" data-sizes="(max-width: 350px) 300px, (max-width: 450px) 400px, (max-width: 766px) 500px, (max-width: 1200px) 300px, 400px">
                        </div>
                        <div class="text">
                            <h3>${recipe.name[0]}</h3>
                            <div class="second-name">${recipe.name[1] ? recipe.name[1] : ''}</div>
                            <div class="diet-info">
                                <div><div>${recipe.calories}</div><div>Cals</div></div>
                                <div><div>${recipe.protein}</div><div>Protein</div></div>
                                <div><div>${recipe.fiber}</div><div>Fiber</div></div>
                            </div>
                            <small>${dietsAsString}</small>
                        </div>
                    </div>
                </div>`;
}

function buildRecipeCarousel($recipeCarousel, recipes) {
    $.each(recipes, function (i, recipe) {
        var recipeImageUrl = recipe.imageURL ? recipe.imageURL : '';

        if (recipeImageUrl) {
            var responsiveImageObj = {
                recipeImage300: recipeImageUrl.replace('.jpg', '@300.jpg'),
                recipeImage400: recipeImageUrl.replace('.jpg', '@400.jpg'),
                recipeImage600: recipeImageUrl.replace('.jpg', '@600.jpg'),
                recipeImage800: recipeImageUrl.replace('.jpg', '@800.jpg'),
                recipeImage900: recipeImageUrl
            };
        }

        var markup = buildSlickRecipe(recipe, responsiveImageObj);
        $recipeCarousel.append(markup);

    });
}

function buildRecipeModal($recipeModal, recipes) {
    $.each(recipes, function (i, recipe) {
        var recipeImageUrl = recipe.imageURL ? recipe.imageURL : '';

        if (recipeImageUrl) {
            var responsiveImageObj = {
                recipeImage300: recipeImageUrl.replace('.jpg', '@300.jpg'),
                recipeImage400: recipeImageUrl.replace('.jpg', '@400.jpg'),
                recipeImage600: recipeImageUrl.replace('.jpg', '@600.jpg'),
                recipeImage800: recipeImageUrl.replace('.jpg', '@800.jpg'),
                recipeImage900: recipeImageUrl
            };
        }

        var markup = buildSlickRecipe(recipe, responsiveImageObj);
        $recipeModal.append(markup);
    });
}

function favorFeaturedRecipes(recipes) {
    const featuredRecipes = recipes.filter(recipe => recipe.recipeLabel === 'Featured');
    const otherRecipes = recipes.filter(recipe => recipe.recipeLabel !== 'Featured');

    return [...featuredRecipes, ...otherRecipes];
}

function setupMenuComponents(recipes, $recipeCarousel) {
    let $zionCarouselParent = $recipeCarousel.attr('id');
    let $zionSlickSlider = $('.zion-slick-slider', $recipeCarousel);
    let $zionMenuModal = $('.zion-menu-modal[data-parent="' + $zionCarouselParent + '"] .zion-modal-content');

    $zionSlickSlider.each(function () {
        let $thisSlider = $(this);

        $thisSlider.on('init', function () {
            $('.slick-active .lazy.recipe-card-img', $thisSlider).lazy({
                threshold: 0
            });

            webUtils.debugParam("Carousel initialized");
        });

        buildRecipeCarousel($thisSlider, recipes);

        $thisSlider.slick({
            arrows: true,
            dots: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            accessibility: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true
                    }
                },
                {
                    breakpoint: 766,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 430,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    });

    $zionMenuModal.each(function () {
        var $recipeModal = $(this);

        $('.on-the-menu .open-menu-cta').on('click', function (event) {
            $('.recipe-img-container .lazy').lazy({
                bind: "event",
                delay: 0,
                threshold: 0
            });
        });

        buildRecipeModal($recipeModal, recipes);
    });
}

function populateRecipes() {
    $('.zion-menu').each(function() {
        const $recipeCarousel = $(this);
        let recipes;
        let source = webUtils.getAjaxUrl('/join/fresh-ready/cms-menu'); // default module option
        let sourceMealKitsOnly = webUtils.getAjaxUrl('/join/mealkits/cms-menu');

        if ($recipeCarousel.hasClass('zionMealKitsOnly')) {
            source = sourceMealKitsOnly;
        }

        $.get({ url: source, cache: false }).done(function (data) {
            recipes = data;

            if ($('.zion-slick-slider', $recipeCarousel).data('favor-featured') == 1) {
                recipes = favorFeaturedRecipes(recipes);
            }
            setupMenuComponents(recipes, $recipeCarousel);
        });
    });
}

$(function () {
    populateRecipes();
});
