import { webUtils } from './web-utils.js';

$(document).ready(function () {
    if (webUtils.isMobile()) {
        $(window).scroll(function () {
            var height = $(window).scrollTop(),
                top = 20;
            if ($('#sticky_bar').length > 0) {
                if (height > top && $('.banner.active').length > 0) {
                    $('#sticky_bar').fadeIn(20);
                    $('body').removeClass('active-banner');
                    $('.banner').hide();
                    $('body .page-wrapper').css('padding-bottom', $('#sticky_bar').outerHeight() + 'px');
                } else if (height < top && $('.banner.active').length > 0) {
                    $('body').addClass('active-banner');
                    $('.banner').fadeIn(20);
                    $('#sticky_bar').fadeOut(20);
                    $('body .page-wrapper').css('padding-bottom', '0');
                } else if (height > top) {
                    $('#sticky_bar').fadeIn(20);
                    $('body .page-wrapper').css('padding-bottom', $('#sticky_bar').outerHeight() + 'px');
                } else {
                    $('#sticky_bar').fadeOut(20);
                    $('body .page-wrapper').css('padding-bottom', '0');
                }
            }
        });
    }
});
