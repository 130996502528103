'use strict';

// load webUtils lib
import { webUtils } from './web-utils.js';
import { recipes } from './recipes-api.js';

(function ($) {
    function slugifyRecipeTag(str) {
        str = str.replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, ' ').toLowerCase();
        str = str.replace(/^\s+|\s+$/gm, '');

        return str.replace(/\s+/g, '-');
    }

    function filterTypesForDisplay(types) {
        var filtered = [];
        var lcTypes = types.map(str => (str.replace(/_/g, ' ').toLowerCase()));

        lcTypes.forEach(function (type) {
            switch (type) {
                case 'lean and clean':
                    filtered.push('lean & clean');
                    break;
                case 'carb conscious':
                    filtered.push('carb-conscious');
                    break;
                case 'dairy free':
                    filtered.push('dairy-free');
                    break;
                case 'gluten free':
                    filtered.push('gluten-free');
                    break;
                case 'diabetes friendly':
                    filtered.push('diabetes-friendly');
                    break;
                case 'soy free':
                    filtered.push('soy-free');
                    break;
                case 'non-gmo project verified':
                    filtered.push('Non-GMO Project Verified');
                    break;
                default:
                    filtered.push(type);
                    break;
            }
        });
        return filtered.join(', ');
    }

    function getRecipeTitle(recipeTag, recipeTagSlug, recipeTagTooltip) {
        if (recipeTag === 'Fresh & Ready') {
            return `<span class="recipe-tag meal-type ${recipeTagSlug} ${recipeTagTooltip}>Fresh & Ready</span>`
        } else {
            return `<span class="recipe-tag ${recipeTagSlug}">Meal Kit&nbsp;</span>
                    <span class="text-sm ${recipeTagTooltip}>${recipeTag}</span>`
        }
    }

    function buildSlickRecipe(recipe, types, responsiveImageObj) {
        var recipeTagSlug = recipe.recipeTag ? slugifyRecipeTag(recipe.recipeTag) : '';
        var recipeTag = '';
        var recipeTagTooltip = '';

        if (recipe.recipeTag) {
            if (recipe.recipeTagDescription) {
                recipeTagTooltip = `title-tooltip" data-title="${webUtils.quoteAttr(recipe.recipeTagDescription)}"`;
            } else {
                recipeTagTooltip = `"`;
            }
            const recipeTitle = getRecipeTitle(recipe.recipeTag, recipeTagSlug, recipeTagTooltip);
            recipeTag = `<p><i class="icon icon-flash-1"></i>${recipeTitle}</p>`;
        } else {
            recipeTag = `<p><span class="recipe-tag">Meal Kit</span></p>`
        }

        var recipeLabelSlug = recipe.recipeLabel ? slugifyRecipeTag(recipe.recipeLabel) : '';
        var recipeLabel = ''
        var recipeLabelTooltip = ''

        if (recipeLabelSlug) {
            if (recipeLabelSlug === 'chef-s-table') {
                recipeLabelTooltip = `<div class="recipe-label" data-placement="top" data-title="Limited-edition recipes designed with exta-luxe ingredients and innovative flavors.">${recipe.recipeLabel}</div>`
            } else {
                recipeLabelTooltip = `<div class="recipe-label">${recipe.recipeLabel}</div>`
            }
            recipeLabel = `<div class="tile-label-container tile-label-${recipeLabelSlug}">${recipeLabelTooltip}</div>`;
        }

        return `<div class="col-sm-12 recipe all ${types.join(' ')}" data-plan="${recipe.menuPlan}" data-recipe-tag="${recipe.recipeTag ? recipe.recipeTag : ''}" >
                    <div class="card">
                        <div class="open-modal plus hidden-xs" href="#" data-id="${recipe.id}" data-toggle="modal" data-target="#modal-${recipe.id}"></div>
                        <div class="open-modal" data-id="${recipe.id}" data-toggle="modal" data-target="#modal-${recipe.id}">
                        <div class="recipe-img-container">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=" data-src="${responsiveImageObj.recipeImage400}" alt="${recipe.name}" class="lazy img-responsive recipe-card-img" data-srcset="${responsiveImageObj.recipeImage900} 900w, ${responsiveImageObj.recipeImage800} 800w, ${responsiveImageObj.recipeImage600} 600w, ${responsiveImageObj.recipeImage400} 400w, ${responsiveImageObj.recipeImage300} 300w" data-sizes="(max-width: 350px) 300px, (max-width: 450px) 400px, (max-width: 766px) 500px, (max-width: 1200px) 300px, 400px">
                        </div>
                        ${recipeLabel}
                        <div class="text">
                            ${recipeTag}
                            <h3>${recipe.name}</h3>
                            <small>${filterTypesForDisplay(types)}</small>
                            <p class="num-servings">Serves ${recipe.numberOfServings}</p>
                        </div>
                        </div>
                    </div>
                </div>`;
    }

    function buildSlickRecipeModal(recipe, types, responsiveImageObj) {
        return `<div class="modal fade view-recipe" id="modal-${recipe.id}">
            <div class="modal-dialog">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><span class="icon icon-close-round"></span></button>
                    <div class="modal-img-container">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=" data-src="${responsiveImageObj.recipeImage800}" alt="${recipe.name}" class="lazy img-responsive modal-recipe-img" data-srcset="${responsiveImageObj.recipeImage300} 300w, ${responsiveImageObj.recipeImage400} 400w, ${responsiveImageObj.recipeImage600} 600w, ${responsiveImageObj.recipeImage800} 800w, ${responsiveImageObj.recipeImage900} 900w" data-sizes="(max-width: 768px) 100vw, 700px">
                    </div>
                    <div class="modal-body">
                        <h2>${recipe.name}</h2>
                        <p class="capitalize">${filterTypesForDisplay(types)}</p>
                                <p>
                                    <span class="bold">${recipe.caloriesPerServing}</span> Calories/Serving, <span class="bold">${recipe.cookTimeLow}</span> Minutes
                                </p>
                                <p>${recipe.description ? recipe.description : ''}</p>
                                <div class="ingredients-container">
                                    <h3>In your bag</h3>
                                    <p class="bag-disclaimer">1 bag serves 2 <span class="four-person-serving">(2 bags serve 4)</span></p>
                                    <ul>
                                        ${Object.keys(recipe.ingredients).map(function (key) {
            return "<li>" + recipe.ingredients[key] + "</li>";
        }).join("")}
                                    </ul>
                                </div>
                                <div class="instructions-container">
                                    <h3>Instructions</h3>
                                    <ol>
                                        ${Object.keys(recipe.instructions).map(function (key) {
            return "<li class='step'><p class='step-number'>" + recipe.instructions[key].step + "</p><p class='step-name'>" + recipe.instructions[key].title + "</p><div class='instruction-detail'>" + recipe.instructions[key].details + "</div></li>";
        }).join("")}
                                    </ol>
                                </div>
                        </div>
                </div>
            </div>`;
    }

    function buildSlickRecipeModalLoggedout(recipe, types, responsiveImageObj) {
        return `<div class="modal fade view-recipe" id="modal-${recipe.id}">
            <div class="modal-dialog">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><span class="icon icon-close-round"></span></button>
                    <div class="modal-img-container">
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=" data-src="${responsiveImageObj.recipeImage800}" alt="${recipe.name}" class="lazy img-responsive modal-recipe-img" data-srcset="${responsiveImageObj.recipeImage300} 300w, ${responsiveImageObj.recipeImage400} 400w, ${responsiveImageObj.recipeImage600} 600w, ${responsiveImageObj.recipeImage800} 800w, ${responsiveImageObj.recipeImage900} 900w" data-sizes="(max-width: 768px) 100vw, 700px">
                    </div>
                    <div class="modal-body">
                        <h2>${recipe.name}</h2>
                        <p class="capitalize">${filterTypesForDisplay(types)}</p>
                                <p>
                                    <span class="bold">${recipe.caloriesPerServing}</span> Calories/Serving, <span class="bold">${recipe.cookTimeLow}</span> Minutes
                                </p>
                                <p>${recipe.description ? recipe.description : ''}</p>

                        <div id="view-more-info${recipe.id}" style="display:none">
                                <div class="ingredients-container">
                                    <h3>In your bag</h3>
                                    <p class="bag-disclaimer">1 bag serves 2 <span class="four-person-serving">(2 bags serve 4)</span></p>
                                    <ul>
                                        ${Object.keys(recipe.ingredients).map(function (key) {
            return "<li>" + recipe.ingredients[key] + "</li>";
        }).join("")}
                                    </ul>
                                </div>
                                <div class="instructions-container">
                                    <h3>Instructions</h3>
                                    <ol>
                                        ${Object.keys(recipe.instructions).map(function (key) {
            return "<li class='step'><p class='step-number'>" + recipe.instructions[key].step + "</p><p class='step-name'>" + recipe.instructions[key].title + "</p><div class='instruction-detail'>" + recipe.instructions[key].details + "</div></li>";
        }).join("")}
                                    </ol>
                                </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-sm-offset-3">
                                <div id="view-more-info${recipe.id}-cta" data-recipe-id="${recipe.id}" class="btn btn-block btn-primary margin-top-xl margin-bottom-0 view-more-info-cta">View more</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>`;
    }

    function sortTypesByMealPlan(types, mealPlan) {
        if (!types || types.length <= 0) {
            return [' '];
        }
        const upperCaseMealPlan = mealPlan != null ? mealPlan.toUpperCase() : null;
        if (types.includes(upperCaseMealPlan)) {
            const indexOfMealPlan = types.indexOf(upperCaseMealPlan);
            types.splice(indexOfMealPlan, 1);
            const sortedRestOfTypes = types.sort();
            return [upperCaseMealPlan, ...sortedRestOfTypes];
        }

        return types.sort();
    }

    function buildRecipeCarousel($recipeCarousel, recipes, mealPlan) {
        recipes.forEach(function (recipe) {
            var types = sortTypesByMealPlan(recipe.types, mealPlan);
            var recipeImageUrl = recipe.imageUrl ? recipe.imageUrl : '';

            if (recipeImageUrl) {
                var responsiveImageObj = {
                    recipeImage300: recipeImageUrl.replace('.jpg', '@300.jpg'),
                    recipeImage400: recipeImageUrl.replace('.jpg', '@400.jpg'),
                    recipeImage600: recipeImageUrl.replace('.jpg', '@600.jpg'),
                    recipeImage800: recipeImageUrl.replace('.jpg', '@800.jpg'),
                    recipeImage900: recipeImageUrl
                };
            }

            var markup = buildSlickRecipe(recipe, types, responsiveImageObj),
                modals;

            if ($("body").hasClass("logged-out")) {
                modals = buildSlickRecipeModalLoggedout(recipe, types, responsiveImageObj);
            } else {
                modals = buildSlickRecipeModal(recipe, types, responsiveImageObj);
            }

            $recipeCarousel.append(markup);
            $('.modals-collector').append(modals);

            $('.instruction-detail', $recipeCarousel).each(function () {
                var recipeText = $(this).html();
                var regexMatch = recipeText.match(/\[(.*?)\]/g);

                if (regexMatch) {
                    $.each(regexMatch, function (key, value) {
                        var replacedText = value.replace('[', '(');
                        replacedText = replacedText.replace(']', ')');
                        recipeText = recipeText.replace(value, "<span class='four-person-serving'>" + replacedText + "</span>");
                    });

                    $(this).html(recipeText);
                }
            });
        });

        var default_config = {};

        default_config = {
            arrows: true,
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            accessibility: false,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        centerPadding: '40px',
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        dots: true
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        centerPadding: '40px',
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true
                    }
                },
                {
                    breakpoint: 766,
                    settings: {
                        arrows: true,
                        centerPadding: '40px',
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true
                    }
                }
            ]
        };

        $recipeCarousel.slick(Object.assign({}, default_config));

        $('.col-sm-12', $recipeCarousel).each(function () {
            var filterClasses = this.className.replace('col-sm-12', '');

            $(this).closest('.slick-slide').addClass(filterClasses.toLowerCase()).data({
                filterClasses: filterClasses.toLowerCase(),
                plan: $(this).data('plan'),
                sort: 0
            });
        });
    }

    function getMealPlanFromURL() {
        const results = new RegExp('[\?&]' + 'mealPlan' + '=([^&#]*)').exec(window.location.href);
        return results == null ? results : results[1] || 0;
    }

    function getMealPlanFromCookie() {
        return window.sunbasket.cookies.get('mealPlan');
    }

    function filterNonPremiumRecipesIfCookieDoesNotExist(recipes) {
        if (window.sunbasket.cookies.get('show_premium_jf')) {
           return [...recipes];
        }
        return recipes.filter(r => r.recipeLabel !== "Chef's Table");
    }

    function getMealPlan() {
        const mealPlanFromUrl = getMealPlanFromURL();
        return mealPlanFromUrl == null? getMealPlanFromCookie() : mealPlanFromUrl;
    }

    function sortRecipesByMealPlan(recipes, mealPlan, slider) {
        const activeSlider = slider;
        const noPrepRecipeTag = 'Fresh & Ready';
        const noPrepPreferred = activeSlider.data('include-no-prep') == 1;

        let prepRecipes = [];
        let recipesWithMealPlan = [];
        let recipesWithoutMealPlan = [];
        let sortedRecipesWithoutMealPlan = [];

        prepRecipes = recipes.filter(recipe => recipe.recipeTag !== noPrepRecipeTag);

        if (mealPlan != null && mealPlan !== 'fresh_and_ready' && noPrepPreferred) {
            recipesWithMealPlan = prepRecipes.filter(recipe => recipe.types.includes(mealPlan.toUpperCase()));
            recipesWithoutMealPlan = prepRecipes.filter(recipe => !recipe.types.includes(mealPlan.toUpperCase()));
            sortedRecipesWithoutMealPlan = sortRecipesWithoutMealPlan(recipesWithoutMealPlan, mealPlan);
        }

        if (noPrepPreferred || mealPlan === 'fresh_and_ready') {
            const noPrepRecipes = recipes.filter(recipe => recipe.recipeTag === noPrepRecipeTag);

            if (mealPlan !== 'fresh_and_ready' && mealPlan != null) {
                const noPrepRecipesWithMealPlan = noPrepRecipes.filter(recipe => recipe.types.includes(mealPlan.toUpperCase()));
                const noPrepRecipesWithoutMealPlan = noPrepRecipes.filter(recipe => !recipe.types.includes(mealPlan.toUpperCase()));
                const sortedNoPrepRecipesWithoutMealPlan = sortRecipesWithoutMealPlan(noPrepRecipesWithoutMealPlan, mealPlan);
                
                return [...noPrepRecipesWithMealPlan, ...sortedNoPrepRecipesWithoutMealPlan, ...recipesWithMealPlan, ...sortedRecipesWithoutMealPlan];
            }

            return [...noPrepRecipes, ...prepRecipes]

        } else {
            if (mealPlan != null) {
                return [...recipesWithMealPlan, ...sortedRecipesWithoutMealPlan];
            }

            return recipes;
        }
    }

    function sortRecipesWithoutMealPlan(recipeSet, mealPlan) {
        const carbConsciousRecipes = recipeSet.filter(recipe => recipe.types.includes('CARB_CONSCIOUS'));
        const diabetesFriendlyRecipes = recipeSet.filter(recipe => recipe.types.includes('DIABETES_FRIENDLY'));
        const glutenFreeRecipes = recipeSet.filter(recipe => recipe.types.includes('GLUTEN_FREE_FRIENDLY'));
        const leanAndCleanRecipes = recipeSet.filter(recipe => recipe.types.includes('LEAN_AND_CLEAN'));
        const mediterraneanRecipes = recipeSet.filter(recipe => recipe.types.includes('MEDITERRANEAN'));
        const paleoRecipes = recipeSet.filter(recipe => recipe.types.includes('PALEO'));
        const pescatarianRecipes = recipeSet.filter(recipe => recipe.types.includes('PESCATARIAN'));
        const veganRecipes = recipeSet.filter(recipe => recipe.types.includes('VEGAN'));
        const vegetarianRecipes = recipeSet.filter(recipe => recipe.types.includes('VEGETARIAN'));

        let result;
        switch (mealPlan) {
            case 'carb_conscious':
                result = [...new Set([...diabetesFriendlyRecipes, ...paleoRecipes, ...glutenFreeRecipes, ...leanAndCleanRecipes, ...mediterraneanRecipes, ...veganRecipes])];
                break;
            case 'diabetes_friendly':
                result = [...new Set([...mediterraneanRecipes, ...carbConsciousRecipes, ...veganRecipes, ...glutenFreeRecipes, ...paleoRecipes])];
                break;
            case 'gluten_free':
                result = [...new Set([...mediterraneanRecipes, ...veganRecipes, ...paleoRecipes, ...diabetesFriendlyRecipes, ...carbConsciousRecipes])];
                break;
            case 'lean_and_clean':
                result = [...new Set([...mediterraneanRecipes, ...glutenFreeRecipes, ...paleoRecipes, ...veganRecipes, ...diabetesFriendlyRecipes, carbConsciousRecipes])];
                break;
            case 'mediterranean':
                result = [...new Set([...veganRecipes,  ...paleoRecipes, ...glutenFreeRecipes, ...diabetesFriendlyRecipes, ...carbConsciousRecipes])];
                break;
            case 'paleo':
                result = [...new Set([...glutenFreeRecipes, ...carbConsciousRecipes, ...mediterraneanRecipes, ...veganRecipes, ...diabetesFriendlyRecipes])];
                break;
            case 'pescatarian':
                result = [...new Set([...veganRecipes, ...mediterraneanRecipes, ...glutenFreeRecipes, ...paleoRecipes, ...diabetesFriendlyRecipes, ...carbConsciousRecipes])];
                break;
            case 'vegan':
                result = [...new Set([...vegetarianRecipes, ...glutenFreeRecipes, ...pescatarianRecipes, ...mediterraneanRecipes, ...paleoRecipes, ...diabetesFriendlyRecipes, ...carbConsciousRecipes])];
                break;
            case 'vegetarian':
                result = [...new Set([...veganRecipes, ...glutenFreeRecipes, ...pescatarianRecipes, ...mediterraneanRecipes, ...paleoRecipes, ...diabetesFriendlyRecipes, ...carbConsciousRecipes])];
                break;
            default:
                result = [...recipeSet];
        }

        return result;
    }

    function favorFeaturedRecipes(recipes) {
        const featuredRecipes = recipes.filter(recipe => recipe.recipeLabel === 'Featured');
        const otherRecipes = recipes.filter(recipe => recipe.recipeLabel !== 'Featured');

        return [...featuredRecipes, ...otherRecipes];
    }

    $(function () {
        $('body').on('click', '.view-more-info-cta', function () {
            $('#view-more-info' + $(this).data('recipeId')).stop().slideToggle('slow');
            $(this).text(($(this).text() === 'View more') ? 'View less' : 'View more');
        });

        const $thisWeekSlickSlider = $('.this-week-slick-slider');

        $('#recipe_filters button#toggle_all').on('click', function (e) {
            e.preventDefault();
            $thisWeekSlickSlider.slick('slickUnfilter');
            $thisWeekSlickSlider.slick('slickFilter', '.all');
        });

        $('#recipe_filters button#toggle_filter').on('click', function (e) {
            e.preventDefault();
            $thisWeekSlickSlider.slick('slickUnfilter');
            $thisWeekSlickSlider.slick('slickFilter', '.family_friendly');
        });

        $thisWeekSlickSlider.each(function () {
            var $recipeCarousel = $(this),
                includeNoPrep = $recipeCarousel.data('includeNoPrep');

            // Initialized ONCE, refiltering won't call this but new events are created
            $recipeCarousel.on('init', function () {

                $recipeCarousel.on('click', '.open-modal', function (event) {
                    $('.modals-collector ' + $(this).data('target') + ' .lazy').lazy({
                        bind: "event",
                        delay: 0,
                        threshold: 0
                    });
                });

                $('.slick-active .lazy.recipe-card-img', $recipeCarousel).lazy({
                    threshold: 0
                });

                webUtils.debugParam("Carousel initialized");
            });

            recipes.get({ includeNoPrep: !!includeNoPrep }, function (recipes) {
                
                webUtils.debugParam('This weeks recipes are', recipes);
                const filteredRecipes = filterNonPremiumRecipesIfCookieDoesNotExist(recipes);
                const mealPlan = getMealPlan();
                let sortedRecipes = sortRecipesByMealPlan(filteredRecipes, mealPlan, $recipeCarousel);
                
                if ($recipeCarousel.data('favor-featured') == 1) {
                    sortedRecipes = favorFeaturedRecipes(sortedRecipes);
                }

                buildRecipeCarousel($recipeCarousel, sortedRecipes, mealPlan);

                webUtils.appendParams('.join');

                $('.text .title-tooltip, .tile-label-container .recipe-label', $recipeCarousel)
                    .attr('data-toggle', 'tooltip')
                    .tooltip({
                        trigger: 'hover',
                        container: '.this-week-slick-slider',
                        delay: 2
                    });
            });
        });

        if ($('.quote-loop').length > 0) {
            (function loop() {
                $('.quote-loop').each(function () {
                    var $self = $(this);
                    $self.parent().queue(function (n) {
                        $self.fadeIn(500).delay(4000).fadeOut(500, n);
                    });
                }).parent().promise().done(loop);
            }());
        }
    });
})(jQuery);
