import { webUtils } from './web-utils.js';

$(function() {

if ($('body.dietid-lp').length) {
    webUtils.smoothScroll($('body .hero-home a.btn'));

    const $dietidFormSuccessModal = $('#dietid-form-success-modal').length || '';
    if ($dietidFormSuccessModal) {
        $('#dietid-form-success-modal').modal('show');
    }

    $('input[type=range]').rangeslider({
        polyfill: false,
    });

    const C25 = 19;
    const C26 = 25;
    const C27 = 30;
    const C28 = 35;
    const C29 = 40;
    const C30 = 50;
    const G25 = 0.05;
    const G26 = 0.15;
    const G27 = 0.25;
    const G28 = 0.35;
    const G29 = 0.45;
    const G30 = 0.55;
    const G31 = 0.65;
    const H25I25 = 0.2;
    const H26I26 = 0.2;
    const H27I27 = 0.25;
    const H28I28 = 0.3;
    const H29I29 = 0.35;
    const H30I30 = 0.4;
    const H31I31 = 0.4;
    const D54D63 = 2000;
    const D55D64 = 1900;
    const D56D65 = 9601;
    const D57D66 = 19000;
    const C35 = 1;
    const D35 = 2;
    const E35 = 3;
    const D36 = 0.4;
    const D37 = 0.4;
    const D38 = 0.45;
    const D39 = 0.07;
    const D40 = 0.25;
    const C36 = D36 * 0.9;
    const C37 = D37 * 0.9;
    const C38 = D38 * 0.9;
    const C39 = D39 * 0.9;
    const C40 = D40 * 0.9;
    const E36 = D36 * 1.1;
    const E37 = D37 * 1.1;
    const E38 = D38 * 1.1;
    const E39 = D39 * 1.1;
    const E40 = D40 * 1.1;
    const F36 = 0;
    const F37 = 0.02;
    const F38 = 0.01;
    const F39 = 0.012;
    const F40 = 0.1;

    window.trackedCalculatorInputs = [];

    $('input[name="population-size"]').on('input', function () {
        const C4 = $('#C4').val().replace(/[^0-9]+/g, '');

        if (C4 > 250000) {
            $('#C4').val('250,000');
        } else if (C4 > 0 && C4 <= 250000) {
            $('#C4').val(parseInt(C4).toLocaleString('en-US'));
        }
    });

    $('input[name="subsidy-per-week"]').on('input', function () {
        const C5 = $('#C5').val();
        let C6;
        if (C5 <= C25) {
            C6 = G25;
        } else if (C5 > C25 && C5 <= C26) {
            C6 = G26;
        } else if (C5 > C26 && C5 <= C27) {
            C6 = G27;
        } else if (C5 > C27 && C5 <= C28) {
            C6 = G28;
        } else if (C5 > C28 && C5 <= C29) {
            C6 = G29;
        } else if (C5 > C29 && C5 <= C30) {
            C6 = G30;
        } else if (C5 > C30) {
            C6 = G31;
        }
        C6 = (C6 * 100).toFixed(0);

        $('output[name="subsidy-per-week-result"]').text(C5);
        $('output[name="participation-rate-result"]').text(C6);
        $('#C6').val(C6).change();
    });

    $('input[name="participation-rate"]').on('input', function () {
        const C6 = $('#C6').val();
        $('output[name="participation-rate-result"]').text(C6);
    });

    $('.roi-calculator input').on('input', function () {
        const inputName = $(this).attr('name');
        if (trackedCalculatorInputs.includes(inputName) === false) {
            trackedCalculatorInputs.push(inputName);

            trackCalculatorEvent(inputName);
        }

        const C4 = $('#C4').val().replace(/[^0-9]+/g, '');

        if (C4 > 0 && C4 <= 250000) {
            const C5 = $('#C5').val();
            let C6 = $('#C6').val() / 100;
            const C7 = $('#C7').val();
            const C17 = 0.5;
            let C18;
            let C19;

            if (C5 <= C25) {
                C18 = H25I25;
                C19 = H25I25;
            } else if (C5 > C25 && C5 <= C26) {
                C18 = H26I26;
                C19 = H26I26;
            } else if (C5 > C26 && C5 <= C27) {
                C18 = H27I27;
                C19 = H27I27;
            } else if (C5 > C27 && C5 <= C28) {
                C18 = H28I28;
                C19 = H28I28;
            } else if (C5 > C28 && C5 <= C29) {
                C18 = H29I29;
                C19 = H29I29;
            } else if (C5 > C29 && C5 <= C30) {
                C18 = H30I30;
                C19 = H30I30;
            } else if (C5 > C30) {
                C18 = H31I31;
                C19 = H31I31;
            }

            const C47 = C4 * C5 * C6 * C17 * 52;
            let C54;
            let C55;
            let C56;
            let C57;

            if (C7 == C35) {
                C54 = C36 * C4;
                C55 = C37 * C4;
                C56 = C38 * C4;
                C57 = C39 * C4;
            } else if (C7 == D35) {
                C54 = D36 * C4;
                C55 = D37 * C4;
                C56 = D38 * C4;
                C57 = D39 * C4;
            } else if (C7 == E35) {
                C54 = E36 * C4;
                C55 = E37 * C4;
                C56 = E38 * C4;
                C57 = E39 * C4;
            }

            const F54 = C54 * C6;
            const F55 = C55 * C6;
            const F56 = C56 * C6;
            const F57 = C57 * C6;
            const G54 = F54 * C19;
            const G55 = F55 * C19;
            const G56 = F56 * C19;
            const G57 = F57 * C19;
            const H54 = G54 * D54D63;
            const H55 = G55 * D55D64;
            const H56 = G56 * D56D65;
            const H57 = G57 * D57D66;
            const H58 = H54 + H55 + H56 + H57;
            let H59;

            if (C7 == C35) {
                H59 = H58 * (1 - C40);
            } else if (C7 == D35) {
                H59 = H58 * (1 - D40);
            } else if (C7 == E35) {
                H59 = H58 * (1 - E40);
            }

            const C63 = F36 * C4;
            const C64 = F37 * C4;
            const C65 = F38 * C4;
            const C66 = F39 * C4;
            const F63 = C63 * C6;
            const F64 = C64 * C6;
            const F65 = C65 * C6;
            const F66 = C66 * C6;
            const G63 = F63 * C18;
            const G64 = F64 * C18;
            const G65 = F65 * C18;
            const G66 = F66 * C18;
            const H63 = G63 * D54D63;
            const H64 = G64 * D55D64;
            const H65 = G65 * D56D65;
            const H66 = G66 * D57D66;
            const H67 = H63 + H64 + H65 + H66;
            const H68 = H67 * (1 - F40);
            const G4 = (H59 + H68).toFixed(0);
            const G5 = ((C47 / (H59 + H68)) * 12).toFixed(1);
            $('#G4').text(parseInt(G4).toLocaleString('en-US'));
            $('#G5').text(G5);

            $('input[name="pipedrive_dietid_form[populationSize]"]').val(C4);
            $('input[name="pipedrive_dietid_form[subsidyPerWeek]"]').val(C5);
            $('input[name="pipedrive_dietid_form[participationRate]"]').val((C6 * 100).toFixed(0));
            $('input[name="pipedrive_dietid_form[diseasePrevalence]"]').val(C7);
            $('input[name="pipedrive_dietid_form[savingsPerYear]"]').val(G4);
            $('input[name="pipedrive_dietid_form[paybackPeriod]"]').val(G5);
        }
    });

    function trackCalculatorEvent(inputName) {
        if (window.analytics) {
            window.analytics.track('ROI Calculated', {
                path: window.location.pathname,
                user_platform: 'wordpress',
                calculator_clicked: inputName
            });
        }
    }
}
});
