import { webUtils } from './web-utils.js';

$(document).ready(function () {
    var total_recipes = $('.roundup-items .recipe-item-container').length;
    var total_buttons = $('.ctas-to-move a.roundup-cta').length;

    var divide_recipes = total_recipes / 3;
    var active_buttons = Math.ceil(divide_recipes);

    function populate_button_containers() {
        for (var i = 1, l = total_recipes; i <= l; i++) {
            $('.recipe-item-container#recipe-' + (i * 3)).after('<div class="cta-after-recipe-' + i + ' center margin-bottom-xxl"></div>');
        }
    }

    function populate_buttons() {
        for (var i = 1, l = active_buttons; i <= l; i++) {
            $('#cta-' + i).appendTo('.cta-after-recipe-' + i);
        }
    }

    if ((total_recipes > 0) && (total_buttons > 0)) {
        $('.roundup-items').addClass('no-items-' + total_recipes.toString());

        if (Number.isInteger(divide_recipes)) {
            populate_button_containers();
        } else {
            populate_button_containers();
            $('.recipe-item-container#recipe-' + total_recipes).after('<div class="cta-after-recipe-' + Math.ceil(divide_recipes) + ' center margin-bottom-xxl"></div>');
        }

        populate_buttons();
    }

    webUtils.smoothScroll($("body .roundup-toc li a, .back-to-toc a"));
});
