import { webUtils } from './web-utils.js';

$(document).ready(function () {

    var exploreCards = $('.explore-meal-plans-content');

    $('.explore-meal-plans-menu-button').click(function () {
        exploreCards.each(function () { $(this).hide(); });
        $(this.id.replace("to", "#")).fadeIn('slow');
        $('.explore-meal-plans-menu-button').removeClass('selected-button');
        $(this).addClass('selected-button');
        var card_id = $(this).attr('id').replace('to', '');
        $('.explore-disclaimer').addClass('hide');
        $('#' + card_id + '-disclaimer').removeClass('hide');
        $(window).resize(); //for lazy load
    });

    //Mobile Carousel
    $(".mobile-explore-slick-slider").slick({
        slidesToShow: 3,
        centerMode: true,
        centerPadding: '140px',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 580,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    });

    setTimeout(function () {
        // Initial setup.
        exploreCards.each(function (i, el) {
            (i === 0) ? $(el).show() : $(el).hide();
        });
    }, 1000);

    $('.mobile-meal-plans-tile-btn').on('click', function (e) {
        window.sunbasket.joinPreferences.save({ mealPlan: this.id });
        webUtils.deleteCookie('noPrepPreferred');
    });

});
