$(document).ready(function(){

  $(window).scroll(function(){
    $('.top-arrow').css('display', $(this).scrollTop() > $(this).height() ? 'inline' : 'none');
  });

  $('.back-to-top').on('click', function(e){
    e.preventDefault();

    $('html,body').animate({ scrollTop: 0 }, 'fast');
  });
  
});
