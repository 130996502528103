$.urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results==null){
       return null;
    }
    else{
       return results[1] || 0;
    }
}

if ($.urlParam('noHeader') == '1') {
    $( 'header' ).remove();
    $( 'footer' ).remove();
    $( 'body' ).addClass('noHeaders');
};

// Pre-populate promo code to hero-promo-code.php
var offer = $.urlParam('offer');
var $promoCodeinput = $('#promoCode');

if (offer && $promoCodeinput.length) {
    $promoCodeinput.val(offer);
}