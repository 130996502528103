'use strict';

export var webUtils = (function () {
    return {
        isMobile: function () {
            return (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera\sMini/i.test(navigator.userAgent));
        },
        hasWebsiteLocalHost: function () {
            let cfg = this.getSunbasketConfig();
            return cfg.hasOwnProperty('website_localhost_url');
        },
        highPixelRatio: function () {
            var query = '(-webkit-min-device-pixel-ratio: 2),\
            (min--moz-device-pixel-ratio: 2),\
            (-o-min-device-pixel-ratio: 2),\
            (min-device-pixel-ratio: 2),\
            (min-resolution: 144dpi),\
            (min-resolution: 2dppx)';
        
            return window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia(query).matches);

        },
        getSunbasketConfig: function () {
            return (typeof sunbasket_config !== 'undefined') ? sunbasket_config : {};
        },
        getAjaxUrl: function (path) {
            let cfg = this.getSunbasketConfig();
            return cfg.hasOwnProperty('website_localhost_url') ? cfg.website_localhost_url + path : path;
        },
        hasCookie: function (name) {
            var r = new RegExp('(^|;) ?' + name + '=([^;]*)(;|$)');
            return r.test(document.cookie);
        },
        setCookie: function (cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            if (!!exdays) {
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            } else {
                document.cookie = cname + "=" + cvalue + ";path=/";
            }
        },
        cookieProtectedOnce: function (name, callee) {
            if (webUtils.hasCookie(name)) {
                return;
            }

            callee();

            webUtils.setCookie(name, 'yes', false);
        },
        getCookie: function (name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length >= 2) return parts.pop().split(";").shift();
        },
        deleteCookie: function (name) {
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
        },
        getCookiesfromMarkup: function() {
            try {
                var $cookies = $('.cookie-setter');
                $.each($cookies, function (key, cookie) {
                    var cookieName = $(cookie).data('setCookieName');
                    var cookieValue = $(cookie).data('setCookieValue');
                    var cookieOverwrite = !!$(cookie).data('setCookieOverwrite');

                    if (!cookieOverwrite && webUtils.hasCookie(cookieName)) {
                        return;
                    }

                    if (cookieValue === 'false') {
                        webUtils.deleteCookie(cookieName);
                    } else {
                        webUtils.setCookie(cookieName, $(cookie).data('setCookieValue'), $(cookie).attr('setCookieExpires'));
                    }
                });
            } catch (error) {
                console.error('could not get cookies from markup')
            }
        },
        setSessionStorage: function (key, value) {
            sessionStorage.setItem(key, value);
        },
        checkSessionStorage: function (key) {
            if (sessionStorage.getItem(key)) {
                return JSON.parse(sessionStorage.getItem(key));
            } else {
                return false;
            }
        },
        checkRoute: function (route) {
            return $.ajax({
                async: false,
                url: route,
                dataType: "jsonp",
                success: function (data) {
                    return true;
                },
                error: function (response) {
                    return false;
                }
            });
        },
        checkIsAuthCookie: function () {
            if (webUtils.getCookie('is_authenticated')) {
                return true;
            }
            return false;
        },
        getParameterByName: function (name, url) {
            if (!url) {
                url = window.location.href;
            }
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) {
                return null;
            }
            if (!results[2]) {
                return "";
            }
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
        setParameterByName: function (key, value) {
            key = encodeURI(key); value = encodeURI(value);
            var kvp = document.location.search.substr(1).split('&');
            var i = kvp.length;
            var x;

            while (i--) {
                x = kvp[i].split('=');

                if (x[0] === key) {
                    x[1] = value;
                    kvp[i] = x.join('=');
                    break;
                }
            }

            if (i < 0) { kvp[kvp.length] = [key, value].join('='); }
            //this will reload the page, it's likely better to store this until finished
            document.location.search = kvp.join('&');
        },
        appendParams: function (selector) {
            var query_params = location.search.substr(1);

            $(selector).attr('href', function () {
                var targetUrl = this.href;
                var hashPart = this.hash;

                if (query_params.length > 0 && (targetUrl !== undefined && targetUrl.length > 0)) {
                    if (hashPart !== "") {
                        targetUrl = targetUrl.substring(0, targetUrl.indexOf("#"));
                    }

                    return targetUrl + (targetUrl.includes('?') ? "&" : "?") + query_params + hashPart;
                }
            });
        },
        removeURLParameter: function (url, parameter) {
            var urlparts = url.split('?');
            if (urlparts.length >= 2) {
                var prefix = encodeURIComponent(parameter) + '=';
                var pars = urlparts[1].split(/[&;]/g);
                for (var i = pars.length; i-- > 0;) {
                    if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                        pars.splice(i, 1);
                    }
                }

                url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
                return url;
            } else {
                return url;
            }
        },

        insertParam: function (key, value) {
            if (history.pushState) {
                var currentUrl = webUtils.removeURLParameter(window.location.href, key);
                var queryStart = currentUrl.indexOf('?') !== -1 ? '&' : '?';
                var newurl = currentUrl + queryStart + key + '=' + value

                window.history.pushState({ path: newurl }, '', newurl);
            }
        },
        params_to_object: function () {
            var search = location.search.substring(1);
            return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        },
        appendJoinFlowVariation: async (selector) => {
            try {
                var variation = webUtils.getCookie('join-flow-variation');

                if (variation && variation.length > 0) {
                    $(selector).attr('href', '/join/' + variation);
                }
            } catch (error) {
                console.error('could not append join flow variation')
            }

        },
        debugParam: function (msg, thing) {
            if (this.getParameterByName('debug') === 'true') {
                if (msg) console.log(msg);
                if (thing) console.log(thing);
            }
        },
        postToLeadCapture: function (emailAddress, listId, completionCallback) {
            let emailData = { "email": emailAddress, "listId": listId };

            $.ajax({
                url: webUtils.getAjaxUrl("/cms/api/newsletter"),
                method: "POST",
                xhrFields: { withCredentials: true },
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
                contentType: "application/json",
                data: JSON.stringify(emailData),
                complete: completionCallback,
                error: function (error) {
                    console.error(error);
                }
            });
        },
        findParentOfkey: function (myArray, parent, value) {
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i][parent] === value) {
                    return myArray[i];
                }
            }
        },
        findByMatchingProperties: function (set, properties) {
            return set.filter(function (entry) {
                return Object.keys(properties).every(function (key) {
                    return entry[key] === properties[key];
                });
            });
        },
        getKeyByidx: function (obj, idx) {
            return Object.keys(obj)[idx];
        },
        percentageBased: function (type) {
            return (type === 'PERCENTAGE' || type === 'DISTRIBUTED_PERCENTAGE');
        },
        getNavbarScrollOffset: function () {
            return (($('header .navbar').height() + $('.banner.active').height()) * -1);
        },
        adjustHeaderPageWrapperHeight: function () {
            if (this.isMobile()) {
                var $banner = $('.banner.active'),
                    $navbar = $('.navbar:not(.hidden)'),
                    $header = $('header'),
                    $page_wrapper = $('.page-wrapper'),
                    wrapper_height = parseInt($page_wrapper.css('padding-top')),
                    top_height = $banner.height() + $navbar.height();

                if (top_height > wrapper_height) {
                    $header.removeAttr('style').attr('style', 'padding-top:' + $banner.height() + 'px');
                    $page_wrapper.removeAttr('style').attr('style', 'padding-top:' + top_height + 'px');
                } else {
                    $header.removeAttr('style');
                    $page_wrapper.removeAttr('style');
                }
            }
        },
        dynamicHeroBackgroundImage: function () {
            $('.js-responsive-bg').each(function () {
                var desktop_image = $(this).data('desktop-image');
                var desktop_image_position = $(this).data('desktop-image-position');
                var mobile_image = $(this).data('mobile-image');
                var mobile_image_position = $(this).data('mobile-image-position');
                if ($(window).width() <= 767) {
                    $(this).css({ 'background-image': 'url(' + mobile_image + ')', 'background-position': + mobile_image_position });
                } else {
                    $(this).css({ 'background-image': 'url(' + desktop_image + ')', 'background-position': + desktop_image_position });
                }
            });
        },
        dynamicHeroBackgroundImageNew: function () {
            $('.js-responsive-bg-new').each(function () {
                var desktop_image = $(this).data('desktop-image');
                var desktop_image_position = $(this).data('desktop-image-position');
                var mobile_image = $(this).data('mobile-image');
                var mobile_image_position = $(this).data('mobile-image-position');
                var image_breakpoint = parseInt($(this).data('image_breakpoint'));
                var set_breakpoint = 767;
                var turn_off_mobile = parseInt($(this).data('turn-off-mobile'));
                var turn_off_desktop = parseInt($(this).data('turn-off-desktop'));
                var defaultMobile = parseInt($(this).data('default-mobile'));

                if (image_breakpoint && !!image_breakpoint) {
                    set_breakpoint = image_breakpoint;
                }

                if ($(window).width() <= (set_breakpoint - 1)) {
                    if (!!turn_off_mobile) {
                        if(!!defaultMobile) {
                            $(this).removeAttr( 'style' );
                        } else {
                            $(this).css({ 'background-image': 'none' });
                        }
                    }
                    else {
                        $(this).css({ 'background-image': 'url(' + mobile_image + ')', 'background-position': + mobile_image_position });
                    }
                } else {
                    if (!!turn_off_desktop) {
                        $(this).css({ 'background-image': 'none' });
                    }
                    else {
                        $(this).css({ 'background-image': 'url(' + desktop_image + ')', 'background-position': + desktop_image_position });
                    }
                }
            });
        },
        autoSameHeightDivs: function (selectors) {
            var maxHeight = 0;
            $(selectors).each(function () {
                if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
            });

            $(selectors).height(maxHeight);
        },
        checkBodyReady: function (element) {
            return new Promise(function (resolve, reject) {
                waitForContainerElement(resolve, element);
            });
        },
        smoothScroll: function (element) {
            element.on('click', function (event) {
                var $target = $(this.getAttribute('href'));
                var $offset = $('header').height();
                if ($target.length) {
                    event.preventDefault();
                    
                    $('html, body').stop().animate({
                        scrollTop: $target.offset().top -($offset+20)
                    }, {
                        duration: 500,
                        specialEasing: {
                            width: "linear",
                            height: "easeInBack"
                        },
                    });
                }
            });
        },
        getCsrfToken: function (successCallback, errorCallback) {
            $.ajax({
                url: webUtils.getAjaxUrl('/cms/api/csrf-token'),
                method: "GET",
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
                contentType: "application/json"
            }).fail(errorCallback).done(successCallback);
        },
        quoteAttr: function (s, preserveCR) {
            preserveCR = preserveCR ? '&#13;' : '\n';
            return ('' + s)
                .replace(/&/g, '&amp;')
                .replace(/'/g, '&apos;')
                .replace(/"/g, '&quot;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/\r\n/g, preserveCR)
                .replace(/[\r\n]/g, preserveCR);
        },
        unquoteAttr: function (s) {
            s = ('' + s)
                .replace(/\r\n/g, '\n')
                .replace(/[\r\n]/, '\n')
                .replace(/&#13;&#10;/g, '\n')
                .replace(/&#1[03];/g, '\n')
                .replace(/&#9;/g, '\t')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<')
                .replace(/&quot;/g, '"')
                .replace(/&apos;/g, "'")
            ;
            var found = /&[^;]*;?/.match(s);
            if (found.length > 0 && found[0] !== '&amp;') {
                throw 'unsafe entity found in the attribute literal content';
            }
            s = s.replace(/&amp;/g, '&');
            return s;
        }
    };

    function waitForContainerElement(resolve, element) {
        var $configElement = $(element);
        if ($configElement.length === 0) {
            setTimeout(waitForContainerElement.bind(this, resolve), 300);
        } else {
            resolve($configElement);
        }
    }
}());
