if ($('body.young-city-solo').length) {

    var heroBubble = $('.hero-home .copy-container');
    // variables for limiting resizing events
    var desktopLayout = true;
    var mobileLayout = false;

    function handleMobieBubble(heroBubble) {
        heroBubble.addClass('yellow-a-bg scroll-animation');
        setTimeout(function () {
            heroBubble.css('transition', 'background-color 1200ms ease-out');
        }, 2000);

        mobileLayout = true;
        desktopLayout = false;
    }

    function resetMobieBubble(heroBubble) {
        heroBubble
            .removeClass('yellow-a-bg')
            .removeClass('yellow-c-bg')
            .removeClass('scroll-animation');

        mobileLayout = false;
        desktopLayout = true;
    }

    if ($(window).width() <= 768) {
        handleMobieBubble(heroBubble);
    }

    $(window).resize(function () {
        if ($(window).width() > 768 && !desktopLayout) {
            resetMobieBubble(heroBubble);

        } else if ($(window).width() <= 768 && !mobileLayout) {
            handleMobieBubble(heroBubble);
        }
    });

    $(window).on("scroll touchmove", function () {
        if (heroBubble.hasClass('scroll-animation')) {
            if ($(document).scrollTop() >= heroBubble.position().top) {
                if (!heroBubble.hasClass('yellow-c-bg')) {
                    heroBubble
                        .removeClass('yellow-a-bg')
                        .css('background-color', '')
                        .addClass('yellow-c-bg');
                }
            } else {
                if (!heroBubble.hasClass('yellow-a-bg')) {
                    heroBubble
                        .removeClass('yellow-c-bg')
                        .addClass('yellow-a-bg');
                }
            }
        }
    });
}
