
if ($('body').hasClass('page-template-meal-plans-new')) {
    var mealPlanNav = $('.meal-plan-nav').outerHeight() || 0,
    lastId,
    topMenu = $(".mp-name-list"),
    // All list items
    menuItems = topMenu.find("a"),
    mpNavLocation = $('.meal-plan-nav').offset().top - mealPlanNav,
    firstMealPlan = $('ul li:first-child').data('id');
    // Anchors corresponding to menu items
    scrollItems = menuItems.map(function(){
        var item = $($(this).attr("href"));
        if (item.length) {
            return item;
        }
    });

    $('.mp-nav-link').click(function (e) {
        var offset = 0;
        var windowWidth = $(window).width();
        // $('nav.navbar').outerHeight(true) + 30;
        offset = $('nav.navbar').outerHeight(true) + mealPlanNav;

        if ($(this).attr('href') !== '#') {
            offset = $($(this).attr('href')).offset().top - offset + 1;
        }

        $('html, body').stop().animate({
            scrollTop: offset
        }, 500);
        e.preventDefault();
    });

    $(window).scroll(function(){
        var scrollLocation = $(window).scrollTop();
        var navHeaderHeight = $('nav').outerHeight() || 0;
        var banner = $('.mymenu-banner').outerHeight() || 0;

        // make meal plans nav sticky

        if (scrollLocation > mpNavLocation  && $(window).width() > 991) {
            $('.meal-plan-nav').addClass('sticky');
        $('.meal-plan-nav').css('top', navHeaderHeight + banner);
            $('.mp-hero').css('margin-top', mealPlanNav);
        } else {
            $('.meal-plan-nav').removeClass('sticky');
        $('.meal-plan-nav').css('top', '');
            $('.mp-hero').css('margin-top', 0);
        }

        // Select the proper meal plan tab
        // Get id of current scroll item
        var cur = scrollItems.map(function(){
          if ($(this).offset().top < scrollLocation + navHeaderHeight + banner + mealPlanNav) {
            return this;
          }
         });

        // set id to current location, but if none of them, default to selecting recipesList
        var id = cur[cur.length-1] && cur[cur.length-1].length ? cur[cur.length-1][0].id : firstMealPlan;

        if (lastId !== id) {
             lastId = id;
             // Set/remove active class
             $('li.active', topMenu).removeClass('active');
             $('a[href="#'+id+'"]', topMenu).parent().addClass('active');
             // if addons subcategory selected, also bold addons header
             $('a[href="#'+id+'"]').parents('.addon-list-item').addClass('active');
        }

    });
}
