'use strict';

import { webUtils } from './web-utils.js';

!webUtils.getCookie('covid-19') ? $('#covid19Banner').show() : '';

$(document).ready(function () {
    $('#closeBannerX').click(function () {
        webUtils.setCookie('covid-19', true);
        $('#covid19Banner').slideUp(300);
        var header_height = $('header').height();
        var covid_height = $('#covid19Banner').height();
        $('.page-wrapper').animate({
            'padding-top': (header_height - covid_height) + 'px'
        }, 300);
    });
});
